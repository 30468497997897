'use strict';

class NavbarController {

    constructor(Auth, Category, Router) {

        this.isLoggedIn = Auth.isLoggedIn;

        this.isAdmin = Auth.isAdmin;

        this.getCurrentUser = Auth.getCurrentUser;

        this.isLinkActive = Router.isLinkActive;

        let self = this;


    }

}

angular.module('mvpcarrefourApp')
    .controller('NavbarController', ['$scope', '$uibModal', 'Auth', '$sce', '$rootScope', 'Router', function($scope, $uibModal, Auth, $sce, $rootScope, Router) {
        $scope.isLoggedIn = Auth.isLoggedIn();
        $scope.animationsEnabled = true;

        // Consulta lista de links activos
        Router.isLinkActive('navbar').then(function(data) {
            let links = [];
            _.forEach(data, function(v, k) {
                links.push(v.state);
            });
            // Evalua cada link
            $scope.isActiveLinkNavbar = function(lnk) {
                return links.indexOf(lnk) !== -1;
            }
        });


        $scope.openAside = function() {

            // Asegurar que el destroy de la intencion de voto, en el caso de que hubiera
            if (!angular.isUndefined($rootScope.votePreLogin)) {
                delete $rootScope.votePreLogin;
            }

            $uibModal.open({
                templateUrl: 'components/navbar/sidebar.html',
                placement: "right",
                animation: $scope.animationsEnabled,
                backdrop: true,
                size: 'sm',
                controller: function($scope, $uibModalInstance, $sce, Router) {

                    // Consulta lista de links activos
                    Router.isLinkActive('sidebar').then(function(data) {
                        let links = [];
                        _.forEach(data, function(v, k) {
                            links.push(v.state);
                        });
                        // Evalua cada link
                        $scope.isActiveLink = function(lnk) {
                            return links.indexOf(lnk) !== -1;
                        }
                    });

                     //Enlace para compartir voto y participar en el sorteo visible para usuarios que han completado la votacion
                    $scope.showShareVote = function() {
                        return !angular.isUndefined($rootScope.totalVoteUser) && $rootScope.totalVoteUser == 15
                    }


                    $scope.trustSrc = function(link) {
                        return $sce.trustAsResourceUrl(link);
                    }
                    $scope.status = Auth.isLoggedIn() ? 'desconectar' : 'conéctate';
                    $scope.url = Auth.isLoggedIn() ?'logout' : 'login';
                    $scope.cancel = function(e) {
                        //aquí ponemos animacion al elemento antes de eliminarlo
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            });
        }

    }])
    .controller('ProgressCtrl', ['$scope', '$rootScope', '$window', '$timeout', 'Auth', function($scope, $rootScope, $window, $timeout, Auth) {
        let totalVotes = angular.isUndefined($rootScope.totalVoteUser) ? 0 : $rootScope.totalVoteUser;
      $scope.max = 15;

        $scope.isLoggedIn = function () {
            return Auth.isLoggedIn();
        }

        angular.element(window).bind('scroll', function() {
            if (angular.element(window).scrollTop() > 150) {

                angular.element(document).find('.wrapper-progress-vote').addClass('mobileScrollTop');
                //angular.element(document).find('grid-panel-content').addClass('mobileNavTop');


            } else {

                angular.element(document).find('.wrapper-progress-vote').removeClass('mobileScrollTop');
                // angular.element(document).find('grid-panel-content').removeClass('mobileNavTop');
            }
        });

    }]);
